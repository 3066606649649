.u-button-fit {
  height: 85%;
  width: 50%;
}

.u-fit-3rd {
  height: 30vh;
}

.u-fit-view {
  height: 90vh;
}