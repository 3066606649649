.u-text-decoration__none {
  text-decoration: none;
}

.u-text-decoration__underline {
  text-decoration: underline;
}

.u-font-weight__bold {
  font-weight: bold ;
}

.u-margin-bottom__small {
  margin-bottom: 0.12em;
}

.u-margin-top__minus1px {
  margin-top: -1px;
}
.u-margin-top__minus1rem {
  margin-top: -1rem;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
