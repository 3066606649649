.u-no-scroll {
  overflow: hidden;
}

.u-no-scroll__vertical {
  overflow-y: hidden;
}

.u-no-scroll__horizontal {
  overflow-x: hidden;
}
