.successbanner {
    background-color: green;
    height: 53px;
    width: 100%;
    color: white;
    padding-top: 15px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    padding-left: 15px;    
    z-index: 4;
}

.errorbanner {
    background-color: #D50000;
    height: 61px;
    width: 100%;
    color: white;
    padding-top: 10px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    padding-left: 15px; 
    font-family: Arial;
    font-style: normal;
    z-index: 4;
}

.crossiconfailure {
    height: 37px;
    margin-left: -50px;
    margin-top: 11px;
    color: white;
    background-color: #D50000;
    font-size: 25px;
    padding-bottom: 30px;
    z-index: 4;
}

.crossiconsuccess {
    height: 28px;
    margin-left: -50px;
    margin-top: 10px;
    color: white;
    background-color: green;
    font-size: 20px;
    padding-bottom: 30px;
    z-index: 4;
}

.banner {
    margin-top: -55px;
    height: 70px;
    width: 100%;
    position: fixed;
    display: flex;
    z-index: 4;
}

.signinerrorbanner {
    margin-top: -55px;
    height: 70px;
    width: 100%;
    position: fixed;
    display: flex;
    z-index: 4;
}

.signinbutton {
   margin-bottom: 200px;
}

.u-z-index__1 {
  height: 31% !important;
  margin-top: -65px;
}

@media only screen and (max-width: 600px) {
    .u-z-index__1 {
        height: 31% !important;
        margin-top: 22px;
      }

      .crossiconfailure {
        height: 33px;
        margin-left: -32px;
        color: white;
        background-color: #D50000;
        font-size: 25px;
        padding-bottom: 30px;
        z-index: 4;
    }
    .signinerrorbanner {
        margin-top: 8px;
        height: 40px;
        width: 100%;
        position: fixed;
        display: flex;
        z-index: 4;
    }
    .successbanner {
        margin-top: 55px;
        background-color: green;
        height: 70px;
        width: 100%;
        color: white;
        padding-top: 30px;
        font-size: 15px;
        line-height: 24px;
        text-align: left;
        padding-left: 15px; 
        z-index: 4;
      }

      .crossiconsuccess {
        height: 45px;
        margin-left: -38px;
        margin-top: 77px;
        color: white;
        background-color: green;
        font-size: 15px;
        padding-bottom: 6px;
        z-index: 4;
    }

    .footer {
        height: 162px
    }

    .button {
        margin-top: -16px;
    }
  }

.c-button-menu__item {
    height: 0%;
    top: 10%;
}

.maintitle1 {
    font-size: 40px;
    font-weight: bold;
    font-family: MaryAnn,Trebuchet MS,sans-serif;
    margin-top: -30px;
}

.site-name {
    margin-top: 50px;
}

.signinbutton {
    max-width: 17.5rem;
    width: 85%;
    margin-bottom: 0% !important;
}

.logoutbutton {
    width: 85%;
    margin-bottom: 0% !important;
}

.maintitle2 {
    margin-top: -10px;
    font-size: 40px;
    font-weight: bold;
    font-family: MaryAnn,Trebuchet MS,sans-serif;
}

@media (max-width: 720px) {
    .maintitle1 {
        font-size: 30px;
    }
    .maintitle2 {
        font-size: 30px;
    }
}

.car-reg-width {
    max-width: 19rem !important;
}