.c-button-menu {
  height: 85vh;
}

.c-button-menu__item {
  height: 31%;

  @include ln-respond-to('max-sm') {
    top: 9%;
  }

  @include ln-respond-to('xs') {
    @media (orientation: portrait) {
      top: 35%;
      height: 25%;
    }

    @media (orientation: landscape) {
      top: 15%;
      height: 50%;
    }
  }
}

.c-button-menu2__item {
  height: 31%;
  top: -2%;

  @media (min-width: 480px) and (max-height: 500px) {
    top: 17%;
  }
}

.c-button-menu__item--fill {
  height: 90%;
 
  @include ln-respond-to('xs'){
    height: 140%;
  }
}

.c_button-pass-saved {
  width: 128px;
  height: 26px;
}
