.centered-content {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 720px) {
    max-width: 90%;
  }
}

.list-identation {
  margin-left: 2rem;
  margin-bottom: 0.5rem;
  margin-top: -1.5rem;

  @media (max-width: 720px) {
    margin-top: -1rem;
  }
}

.custom-text-button {
  color: #f06c00;
  font-size: 1.125rem;
  line-height: 1.33333;
  font-family: MaryAnn, "Trebuchet MS", sans-serif;
  font-weight: 700;
  box-shadow: 0 0 0 3px transparent;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: box-shadow, background-color, border-color, color;
  transition-property: box-shadow, background-color, border-color, color;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  background-color: transparent;
  background-image: none;
  border: 2px solid transparent;
  border-radius: 2px;
  text-decoration: underline;
  touch-action: manipulation;
  -webkit-appearance: button;
  cursor: pointer;
}

.custom-text-button:hover, .custom-text-button:focus {
  color: #e55000;
}

.custom-margin-left {
  margin-left: 1rem !important;
  @media (min-width: 720px) {
    margin-left: 1.5rem !important;
  }
}

.custom-margin-top {
  margin-top: 1rem;
}

.margin-top-minor {
  margin-top: 0.1rem;
}

//over-riding luna's styles

.ln-c-field-info--error {
  margin-bottom: 0.5rem;
  margin-top: 0;
}