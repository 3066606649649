.autocomplete{
    width: 50%;
}
.taskname{
    margin-left: 15px;
    margin-top: 12px;
}
.ln-c-form-group.has-error .ln-c-field-info--error::before {
  display: none;
}
