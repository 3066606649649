.c-number-square-text {
  position: absolute !important;
  width: 100% !important;
}

.c-number-square-size {
  flex: 1 0 21%;
  margin: 0.5rem;
  width: 15vw;
  height: 15vw;
}

.c-number-square-font {
  font-size: 24px;
  font-weight: 500;
}

.c-number-square-container {
  align-self: center;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  
  @include ln-respond-to('xs') {
    justify-content: center;
  }

  @include ln-respond-to('sm') {
    padding-left: 0 !important;
    justify-content: flex-start;
  }

  @include ln-respond-to('md') {
    padding-left: 0 !important;
    justify-content: flex-start;
  }
  @include ln-respond-to('lg') {
    padding-left: 0 !important;
    justify-content: flex-start;
  }
  @include ln-respond-to('xl') {
    padding-left: 0 !important;
    justify-content: flex-start;
  }
  @include ln-respond-to('xxl') {
    padding-left: 0 !important;
    justify-content: flex-start;
  }
}

.c-number-square-line-break {
  align-self: center;
  width: 100% !important;
  height: 1px !important;
  @include ln-respond-to('xs') {
    width: 25% !important;
  }

  @include ln-respond-to('sm') {
    width: 11% !important;
  }
  @include ln-respond-to('md') {
    width: 15% !important;
  }
  @include ln-respond-to('lg') {
    width: 21% !important;
  }
  @include ln-respond-to('xl') {
    width: 20% !important;
  }
  @include ln-respond-to('xxl') {
    width: 15% !important;
  }
}