.c-footer {
    background-Color: white;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%
}

.c-footer-wrapper {
display: block;
padding: 20px;
height: 15vh;
width: 100%;
}

.c-footer__top-shadow {
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 00) 0%, rgba(0, 0, 0, 0.15) 7%, white 7%);
  overflow: hidden;
}
