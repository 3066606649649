.c-badge-background {
  position: absolute;
  background-color: white;
  height: 100%;
  top: 0px;
  width: 100%;
  z-index: -1;
}

.c-badge-container {
  padding-top: 25vh;
}

.c-badge-gif {
  border-radius: 50%;
  width: 50%;
}

.c-badge-details {
  padding: 2rem 0;
  font-size: 28px;
  font-weight: bold;
  font-family: MaryAnn,Trebuchet MS,sans-serif;
}

.c-badge-btn {
  padding: 1rem 0;
  position: absolute;
  bottom: 0;
  left: 40%;
}

@media only screen and (min-width: 769px) {
  .c-badge-gif {
    width: 12%;
  }

  .c-badge-btn {
    left: 47%;
  }

  .c-badge-container {
    padding-top: 10vh;
  }
}