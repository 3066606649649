  .u-z-index__0 {
   z-index: 0;
  }
 
 .u-z-index__1 {
   z-index: 1;
  }

  .u-z-index__2 {
   z-index: 2;
  }

  .u-z-index__3 {
   z-index: 3;
  }