.c-header-wrapper {
  position: fixed;
  top: 0;
  height: 40;
  background-Color: white;
  z-index: 3;
  max-width: 100vw !important;

  @include ln-respond-to('md') {
      max-width: 90rem !important;
  }
}

.c-header {
  font-family: 'MaryAnn';
  font-size: 24px;
  line-height: 16px;
  color: $ln-color-orange;
  position: relative;
  top: -3px; /* Magic number for forcing the header text to align vertically with the button icon */
}

.c-header-menu-link::before {
  background: transparent !important
}

.c-main-content {
  padding-top: 4%;

  @include ln-respond-to('max-sm') {
    padding-top: 12%;
  }

  @include ln-respond-to('xs') {
    @media (orientation: portrait) {
      padding-top: 12%;
    }

    @media (orientation: landscape) {
      padding-top: 8%;
      padding-bottom: 2%;
    }
  }
}