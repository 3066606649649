.c-carousel-buttons-width__130 {
  width: 130px;
}

.c-carousel-buttons-width__100 {
  width: 100px;
}

.c-carousel-buttons-width {
  width: 26vw;
}

.c-carousel-buttons-margin-top__13 {
  margin-top: 13px;
}

//override this as the luna alert X does not line up correctly
.ln-c-dismiss {
  min-height: 0 !important;
  min-width: 0 !important;
}