.c-carousel-slider {
  position: relative;
  margin: 0 auto;
  white-space: nowrap;
}

.c-carousel-slider-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}

.c-carousel-slide {
  display: inline-block;
  height: 100%;
  width: 100%;
}

/* starting ENTER animation */
.nextQuestion-enter {
  position: absolute;
  transform: translate(100%);
  z-index: 2;
}

/* ending ENTER animation */
.nextQuestion-enter-active {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
  opacity: 0.3;
  z-index: 2;
}

/* starting EXIT animation */
.nextQuestion-exit {
  transform: translate(0%);
  visibility: visible;
}

/* ending EXIT animation */
.nextQuestion-exit-active {
  transform: translate(-100%);
  transition: transform 500ms ease-in-out;
}


/* starting ENTER animation */
.backQuestion-enter {
  position: absolute;
  transform: translate(-100%);
}

/* ending ENTER animation */
.backQuestion-enter-active {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
  opacity: 0.3;
}

/* starting EXIT animation */
.backQuestion-exit {
  transform: translate(0%);
  visibility: visible;
}

/* ending EXIT animation */
.backQuestion-exit-active {
  transform: translate(100%);
  transition: transform 500ms ease-in-out;
}